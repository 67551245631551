import React, { useState, useEffect } from "react";
import { db, isSandboxMode } from "./firebase";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faClipboardQuestion,
} from "@fortawesome/free-solid-svg-icons";
import "./CoPilotChat.css";
import uselyLogo from "./images/usely-logo.svg"; // Import the SVG logo
import LoadingDots from "./components/LoadingDots";

// Add utility function above component
const formatTimestamp = (timestamp) => {
  try {
    if (!timestamp) return "";

    // Handle Firestore timestamp object
    if (timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        month: "short",
        day: "numeric",
      }).format(date);
    }

    return "";
  } catch (error) {
    console.error("Error formatting timestamp:", error);
    return "";
  }
};

const CoPilotChat = ({ onClose }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [error, setError] = useState(null); // Track errors
  const [loading, setLoading] = useState(false);
  const defaultPrompts = [
    "Tell me what areas to focus on usability issues?",
    "What are the highest scoring products by heuristics?",
    "What are the lowest scoring products by heuristics?",
    "What are the common themes regarding usability?",
  ];

  const auth = getAuth();
  const currentUserId = auth.currentUser?.uid;

  useEffect(() => {
    const fetchPreviousDiscussions = async (currentUserId) => {
      if (!currentUserId) {
        console.error("Invalid currentUserId:", currentUserId);
        setError("Invalid user ID.");
        return;
      }

      try {
        const sessionRef = collection(db, "ai_sessions");
        const q = query(
          sessionRef,
          where("currentUserId", "==", currentUserId)
        );
        const sessionSnapshot = await getDocs(q);
        const previousMessages = sessionSnapshot.docs
          .map((doc) => doc.data().messages)
          .flat();
        setMessages(previousMessages);
      } catch (err) {
        console.error("Error fetching previous discussions:", err.message);
        setError("Failed to load previous discussions.");
      }
    };

    if (currentUserId) {
      fetchPreviousDiscussions(currentUserId);
    }

    const welcomeMessage = {
      role: "assistant",
      content:
        "Hey there! 👋 I'm Usely, your AI assistant. I'm here to help you dive into usability tracking and share all the exciting progress we’re making. Don’t hesitate to ask me anything—let's chat!",
    };
    setMessages((prev) => [...prev, welcomeMessage]);
  }, [currentUserId]);

  const API_URL_SANDBOX =
    process.env.NODE_ENV === "production"
      ? "https://us-central1-uselyapp-sandbox.cloudfunctions.net/generateResponse"
      : "http://localhost:5001/uselyapp-sandbox/us-central1/generateResponse";

  const API_URL_PROD =
    process.env.NODE_ENV === "production"
      ? "https://us-central1-sprinklr-use-framework.cloudfunctions.net/generateResponse"
      : "http://localhost:5001/sprinklr-use-framework/us-central1/generateResponse";

  const API_ENV = isSandboxMode() ? API_URL_SANDBOX : API_URL_PROD;

  const parseHTMLToJSX = (content) => {
    const parseBoldText = (text) => {
      const parts = text.split(/(\*\*.*?\*\*)/);
      return parts.map((part, i) => {
        if (part.startsWith("**") && part.endsWith("**")) {
          return <strong key={i}>{part.slice(2, -2)}</strong>;
        }
        return part;
      });
    };

    const lines = content.split("\n").map((line, index) => {
      if (line.startsWith("### ")) {
        return <h3 key={index}>{parseBoldText(line.slice(4))}</h3>;
      } else if (line.startsWith("## ")) {
        return <h2 key={index}>{parseBoldText(line.slice(3))}</h2>;
      } else if (line.startsWith("# ")) {
        return <h1 key={index}>{parseBoldText(line.slice(2))}</h1>;
      } else if (line.startsWith("- ")) {
        return (
          <ul key={index}>
            {line
              .split("- ")
              .filter(Boolean)
              .map((item, i) => (
                <li key={i}>{parseBoldText(item.trim())}</li>
              ))}
          </ul>
        );
      } else {
        return <p key={index}>{parseBoldText(line)}</p>;
      }
    });
    return lines;
  };

  const sendMessage = async (messageContent) => {
    const timestamp = Timestamp.now();
    const userMessage = {
      role: "user",
      content: messageContent,
      userMessageTimestamp: timestamp,
    };

    // Add user message immediately
    setMessages((prev) => [...prev, userMessage]);

    // Add loading message
    const loadingMessage = {
      role: "assistant",
      content: "",
      isLoading: true,
      userMessageTimestamp: timestamp,
    };

    setMessages((prev) => [...prev, loadingMessage]);
    setInput("");
    setLoading(true);

    try {
      const response = await fetch(API_ENV, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          message: messageContent.trim(),
          currentUserId,
          userMessageTimestamp: timestamp,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error?.message || "An unexpected error occurred."
        );
      }
      const { content } = await response.json();
      const botMessage = { role: "assistant", content };
      // Replace loading message with real response
      setMessages((prev) =>
        prev.map((msg) =>
          msg.isLoading
            ? { ...botMessage, userMessageTimestamp: timestamp }
            : msg
        )
      );
    } catch (err) {
      console.error("Error fetching response:", err.message);
      // Remove loading message on error
      setMessages((prev) => prev.filter((msg) => !msg.isLoading));
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="co-pilot-chat">
      <div className="chat-header">
        <h3>
          Usely AI <span>(powered by Chat GPT 4.o)</span>
        </h3>
        <button onClick={onClose} className="close-chat-button">
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>

      <div className="chat-messages">
        {messages
          .filter((msg) => msg.role)
          .sort((a, b) => {
            const timeA = a.userMessageTimestamp?.seconds || 0;
            const timeB = b.userMessageTimestamp?.seconds || 0;

            // Primary sort by seconds (descending)
            if (timeA !== timeB) {
              return timeA - timeB;
            }

            // Secondary sort by nanoseconds (descending)
            const nanoA = a.userMessageTimestamp?.nanoseconds || 0;
            const nanoB = b.userMessageTimestamp?.nanoseconds || 0;
            return nanoA - nanoB;
          })
          .toReversed()
          .map((msg, i) => (
            <div key={i} className={`message ${msg.role}`}>
              {msg.isLoading ? (
                <LoadingDots />
              ) : msg.role === "assistant" ? (
                <div className="assistant-message">
                  <img src={uselyLogo} alt="Usely" className="logo" />

                  <div className="assistant-message-body">
                    {parseHTMLToJSX(msg.content)}
                  </div>
                </div>
              ) : (
                <p>
                  {msg.content}
                  <span className="timestamp">
                    {formatTimestamp(msg.userMessageTimestamp)}
                  </span>
                </p>
              )}
            </div>
          ))}
        <div className="default-prompts">
          {defaultPrompts.map((prompt, index) => (
            <button
              key={index}
              onClick={() => {
                setInput(prompt);
                sendMessage(prompt);
              }}
              className="prompt-button"
            >
              {prompt}
            </button>
          ))}
        </div>
        <div className="welcome-message">
          Hey there! 👋 I'm Usely, your AI assistant. I'm here to help you dive
          into usability tracking and share all the exciting progress we’re
          making. Don't hesitate to ask me anything—let's chat!
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={
            loading ? "Awesome question! Hang tight..." : "Ask me anything..."
          }
          disabled={loading}
        />
        <button onClick={() => sendMessage(input)} disabled={loading}>
          {loading ? "Looking..." : "Send"}
        </button>
      </div>
    </div>
  );
};

export default CoPilotChat;
// latest working code
